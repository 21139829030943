import React from "react"
import { defaultArea } from "../constants";
import { get, find, isEmpty } from "lodash"
import GlobalContext from '../context/GlobalContext';
import Select from "react-select"
import { Col, Container, Row, Form } from "react-bootstrap"
import $ from "jquery"
import qs from "qs"
import algoliasearch from "algoliasearch/lite"
import Cookies from "universal-cookie"
import { Link } from "@StarberryUtils"
import { navigate } from "@reach/router"
import Loadable from "@loadable/component"
import { isMobileOnly, isMobile, isTablet } from "react-device-detect"
import "../components/SearchResult/SearchMenu.scss"
import NoResultList from "../components/SearchResult/NoResultList"
import SearchListItems from "../components/SearchResult/SearchListItems"
import LoadmorePagination from "../components/SearchResult/LoadmorePagination"
import LoadPagination from "../components/SearchResult/LoadPagination"
import LoadpreviousPagination from "../components/SearchResult/LoadpreviousPagination"


import { toTitleCase } from "../components/common/site/utils";
// import { isMobileTab, isMobile } from "../jsutility/";


import SeoResults from "../components/common/site/newhomeseoResults"
import {
  getPriceStr,
} from "../components/common/site/utils"
import {
  parseSearchUrl,
  propertyH1
} from "../components/common/site/newhomesutils"

import Footer from "../components/Footer/Footer"
import {
  milesToMeter,
  isAreaMatch,
  getLatLan
} from "../components/SearchResult/filter/constant"
import RefineFilter from "../components/SearchResult/filter/newhomes"
import MobileFilter from "../components/SearchResult/filter/NewHomesMobileFilter"
const MapResultsDesktop = Loadable(() => import("../components/map/open-view-map"))

const { parseResultsUrl, generateFiltersfromPageData } = require("@starberry/gatsby-library-common/PropertySearchNew")

//const { parseResultsUrl, generateFiltersfromPageData } = require("../gatsby-library-common/PropertySearchNew")


var minpriceval = minpriceval || ""
var maxpriceval = maxpriceval || ""
var minpricevalrentals = minpricevalrentals || ""
var maxpricevalrentals = maxpricevalrentals || ""


const index_name = `${process.env.GATSBY_ALGOLIA_NEWHOME_INDEX_NAME}`

const customStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#B20838" : null,
      color: isFocused ? "#ffffff" : "#575757",
    }
  },
  control: styles => ({
    ...styles,
    backgroundColor: null,
    border: 0,
    paddingLeft: 0,
    outline: 0,
    boxShadow: "none",
    color: "#4c505b",
    fontSize: ".875rem",
    fontWeight: "300",
  }),
  valueContainer: styles => ({
    ...styles,
    fontSize: ".875rem",
    paddingLeft: 0,
    paddingRight: 0,
    lineHeight: "30px",
    fontWeight: "300",
    cursor: "pointer",
  }),
  dropdownIndicator: styles => ({
    ...styles,
    //  color: "#ffffff",
  }),
  indicatorsContainer: styles => ({
    ...styles,
    // color: '#ffffff',
    padding: 0,
    cursor: "pointer",
  }),
  indicatorSeparator: () => null,
  placeholder: defaultStyles => {
    return {
      ...defaultStyles,
      color: "#4c505b",
      marginLeft: 0,
    }
  },
}

const sort_by_options = [
  {
    value: index_name,
    label: "Sort by",
  },
  {
    value: index_name + "_price_desc",
    label: "Highest Price First",
  },
  {
    value: index_name + "_price_asc",
    label: "Lowest Price First",
  },
]

// Dropdown react select styles


class PropertySearchSales extends React.Component {
  isUserInteraction = true;
  constructor(props) {
    super(props)
  }

  /* ========= Local Cache =========== */
  local_storage_data = typeof window !== 'undefined' && localStorage.getItem('algolia_data') ? JSON.parse(localStorage.getItem('algolia_data')) : [];

  //
  state = {
    //searchState: urlToSearchState(typeof window !== "undefined" ? window.location : ""),
    algoliadata: [],
    my_page_url_data: [],
    chkalgolia: true,
    userObjects: [],
    areaList: this.props.areaList,
    includeSoldinside: false,
    includeLetinside: false,
    status: this.props.pstatustype,
    aroundRadius: '',
    aroundLatLng: false,
    radius_val: 3,
    screenSize: 0, 
    showDesc: false,
    modalSharefrndformOpen:false,

    local_cache: this.local_storage_data  // ========= Local Cache ===========  
  }



  componentDidUpdate(prevProps, prevState) {

    if (prevProps.location !== this.props.location) {
      //this.setState({ searchState: urlToSearchState(index_name, this.props.location) })
      this.isUserInteraction = true;
      this.getAlgoliaResults();
      //console.log("local_cache ==>", this.state.local_cache)

      //console.log("areaList ==> DidUpdate", this.props.areaList, this.state.areaList)
    }

  }


  includeSoldclick = e => {
    this.setState({ includeSoldinside: !this.state.includeSoldinside }, () => {
      this.isUserInteraction = true
      this.getAlgoliaResults()
    })
  }

  includeLetclick = e => {
    this.setState({ includeLetinside: !this.state.includeLetinside })
    this.getAlgoliaResults()
  }

  componentDidMount() {
    window.addEventListener('popstate', this.onPopState);
    this.getAlgoliaResults();
    this.deviceWidth()
    //console.log("areaList ==> DidMoun", this.props.areaList, this.state.areaList)
    window.addEventListener("resize", () => {
        this.deviceWidth()
    });
    // getting area list
    if (!this.state.areaList.length && this.props.areaList.length) {
      this.setState({ areaList: this.props.areaList })
    }

    $(document).mouseup(function (e) {
      var container = $(".price-filter-dropdown")

      // if the target of the click isn't the container nor a descendant of the container
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        $(".price-filter-dropdown").removeClass("active")
      }
    })

    window.addEventListener("scroll", this.handleScroll, true)

    // load on top
    $(document).ready(function () {
      $("html, body").scrollTop(0);
    })
    // load on top

    //
    $(".property_sortby_asc").click(function () {
      $(".property_sortby_asc").addClass("active")
      $(".property_sortby_desc").removeClass("active")
    })

    $(".property_sortby_desc").click(function () {
      $(".property_sortby_desc").addClass("active")
      $(".property_sortby_asc").removeClass("active")
    })
    //

    // iPhone input fields zoom fix
    $("head").append(
      '<meta name="viewport" content="width=device-width, initial-scale=1.0, shrink-to-fit=no, maximum-scale=1.0, user-scalable=0">'
    )
    // iPhone input fields zoom fix
  }

  componentWillUnmount() {
    //clearTimeout(this.debouncedSetState);
    window.removeEventListener('popstate', this.onPopState);
  }

  deviceWidth = () =>{
    if (window) { 
      this.setState({ screenSize:   window.innerWidth }) 
    }
  }

  openSharefrndformModal = () => {
    this.setState({modalSharefrndformOpen:true});
}
 closeSharefrndformModal = () => {
  this.setState({modalSharefrndformOpen:false});
}


  // Get Algolia Filter
  getAlgoliaResults = () => {
    let base_path = this.props.locationname   // property-for-sale, property-to-rent and etc..
    let search_type = this.props.ptype   // sales, lettings 
    let department_type = this.props.pcategorytype   // residential, commercial 
    let property_status = this.props.ptypetag   // for-sale, to-rent

    const page_url_data = parseResultsUrl(this.props.location, base_path, search_type, department_type, property_status, index_name)

    //console.log("page_url_data ==>", page_url_data['radiusVal'])

    if (page_url_data) {
      this.setState({ my_page_url_data: page_url_data })

      let myalgoliafilters = generateFiltersfromPageData(page_url_data['departmentVal'], page_url_data['searchtypeVal'], page_url_data['areaVal'], page_url_data['bedVal'], page_url_data['minpriceVal'], page_url_data['maxpriceVal'], page_url_data['buildingval'], page_url_data['mustincludeVal'], page_url_data['furnishVal'], page_url_data['branchVal'])

      let mystatus = ''
      var defaultStatus = this.state.includeSoldinside ? ["For Sale", 'Sold STC'] : this.props.pstatustype;
      if (this.props.ptype === "lettings") {
        defaultStatus = this.state.includeLetinside ? ["To Let", "Let Agreed"] : this.props.pstatustype;
      }

      if (Array.isArray(defaultStatus)) {
        mystatus = '(status:"' + (defaultStatus).join('" OR status:"') + '")'
      } else {
        mystatus = '(status:"' + (defaultStatus) + '")'
      }
      myalgoliafilters += ' AND ' + mystatus

      myalgoliafilters = myalgoliafilters.replace("(publish: true) AND", '')

      //console.log("myalgoliafilters", myalgoliafilters);

      //console.log("includeSoldinside", this.state.includeSoldinside, this.isUserInteraction, defaultStatus, myalgoliafilters);


      const client = algoliasearch(process.env.GATSBY_ALGOLIA_APP_ID, process.env.GATSBY_ALGOLIA_SEARCH_KEY);
      const index = client.initIndex(page_url_data['sortVal'] ? page_url_data['sortVal'] : page_url_data['indexVal']);


      /* ========= Local Cache =========== */
      let path_name = typeof window !== 'undefined' ? window.location.href : ''
      var expTime = new Date().setMinutes(new Date().getMinutes() + 5);
      let filter_local_cache = this.filterLocalCache(path_name);

      if (filter_local_cache?.length > 0) {
        //console.log("algoliadata 1", filter_local_cache)
        let get_data = filter_local_cache[0];
        let get_data_pathname = get_data[path_name]
        //console.log("get_data ==>", get_data_pathname);

        if (get_data_pathname?.total > 0) {
          this.setState({
            algoliadata: get_data_pathname
          })
        } else {
          this.setState({ algoliadata: [] })
          //this.getNoResultProperties();
        }

        this.isUserInteraction = false;

      } else {

        if (this.isUserInteraction) {

          // Filters declared here
          let filters = {
            filters: myalgoliafilters,
            page: (page_url_data['pageVal'] - 1),
            hitsPerPage: (this.state.isGridView ? 20 : 20),
          }

          let areaVal = (page_url_data['areaVal'] === (defaultArea.name).toLowerCase() ? '' : page_url_data['areaVal']);

          let radiusVal = page_url_data['radiusVal'] || this.state.radius_val;

          //console.log("areaList ==> 1", radiusVal, this.state.areaList)

          // Radius filter start here
          if (radiusVal && this.state.areaList.length > 0) {
            let aroundLatLngTmp = this.state.aroundLatLng;
            let selectedArea = '';
            selectedArea = isAreaMatch(this.state.areaList, areaVal);

            if (!isEmpty(selectedArea)) {
              aroundLatLngTmp = getLatLan(selectedArea);
            }

            if (radiusVal && !isEmpty(aroundLatLngTmp && getLatLan(selectedArea))) {
              //console.log("useEffect => 2", aroundLatLngTmp, milesToMeter(radiusVal));
              this.setState({
                aroundLatLng: aroundLatLngTmp,
                aroundRadius: milesToMeter(radiusVal),
                radius_val: radiusVal
              })

              filters = { ...filters, aroundLatLng: aroundLatLngTmp, aroundRadius: milesToMeter(radiusVal) }
              areaVal = ''
            }
          }
          // Radius filter end here


          //console.log("radiusfilter", filters);

          index.search(areaVal, filters).then(({ nbHits, page, hitsPerPage, nbPages, hits }) => {
            //console.log("hits ==>", hits, nbHits)

            /* ========= Local Cache expTime =========== */
            let myArray = { "total": nbHits, "current_page_number": page, "hitsPerPage": hitsPerPage, "number_of_pages": nbPages, "hits": hits, expTime: expTime };

            if (nbHits > 0) {
              this.setState({ algoliadata: myArray })
              this.isUserInteraction = false;
              this.forceUpdate();
            }
            else {
              this.setState({ algoliadata: [] })
              this.isUserInteraction = false;
              this.forceUpdate();
            }

            /* ========= Local Cache =========== */
            this.setState({
              local_cache: [...this.state.local_cache, { [path_name]: myArray }]
            }, () => localStorage.setItem('algolia_data', JSON.stringify(this.state.local_cache))
            )

          });
        }

      }

    }
  }


  /* ========= Local Cache =========== */
  filterLocalCache = (path_name) => {
    let get_local_cache = this.state.local_cache;
    let filter_local_cache = [];
    // filter_local_cache = get_local_cache.length > 0 && get_local_cache.filter(key => key[path_name]);

    if (get_local_cache?.length > 0) {
      let remove_local_cache = get_local_cache?.filter(key => Object.values(key)[0]?.expTime > (new Date().getTime()))

      this.setState({
        local_cache: remove_local_cache
      }, () => localStorage.setItem('algolia_data', JSON.stringify(remove_local_cache))
      )

      filter_local_cache = remove_local_cache?.length > 0 && remove_local_cache?.filter(key => key[path_name]);
    }

    //console.log("algoliadata 2", path_name, this.state.local_cache, filter_local_cache)

    return filter_local_cache;
  }


  onPopState = ({ state }) => {
    this.setState({
      searchState: state || {},
    });
  }

  // Sales & Rent

  onClickBuy = e => {
    if (this.props.locationname != "/property-for-sale/") {
      navigate(`/property-for-sale/in-${defaultArea.slug}/`)
    }
  }

  onClickRent = e => {
    if (this.props.locationname != "/property-to-rent/") {
      navigate(`/property-to-rent/in-${defaultArea.slug}/`)
    }
  }
  // Sales & Rent


  //SortBy
  sortByChange = event => {
    //console.log("event =>", event)

    var sort_path = "";
    if (event.value === index_name + "_price_asc") {
      sort_path = "sortby-price-asc/";
    } else if (event.value === index_name + "_price_desc") {
      sort_path = "sortby-price-desc/";
    } else {
      sort_path = "";
    }

    var location_path_remove = this.props.fullpathname.replace('sortby-price-desc/', '').replace('sortby-price-asc/', '');
    var search_url = location_path_remove + sort_path
    navigate(search_url)
  }


  render() {

    const capitalize = s => {
      if (typeof s !== "string") return ""
      return s.charAt(0).toUpperCase() + s.slice(1)
    }


    const { searchState, noResultProperties, includes, my_page_url_data } = this.state;
    const searchParams = parseSearchUrl(this.props.ptype, this.props.fullpathname)

    const h1 = propertyH1(searchParams)

    const areaName = searchParams.areas

    const pType = searchParams.pType

    let propertyType = ""
    if (searchParams.type == "") {
      propertyType = "property "
    } else {
      propertyType = searchParams.type
    }

    const actionStr = "sales"
    // const actionDescStr = ACTION_DESC_TYPES[pType];

    var description = `Looking for ${capitalize(
      this.props.pcategorytype
    )} ${capitalize(propertyType)}${actionStr}${searchParams.areas
      }. Redbrik property experts are here to help with all your property needs.`
    if (searchParams.type) {
      description = `Check out the ${capitalize(propertyType)}${actionStr}${searchParams.areas
        } with Redbrik, expert real estate agents in ${searchParams.areas}.`
    }
    if (searchParams.price.min || searchParams.price.max) {
      description = `Discover the wide range of ${capitalize(
        this.props.pcategorytype
      )} ${capitalize(propertyType)}${actionStr}${searchParams.areas
        } with Redbrik. Book an appointment with one of our property expert today to find the perfect property anywhere in ${searchParams.areas
        }.`
    }
    if (searchParams.bedrooms) {
      description = `Browse ${searchParams.bedrooms} bedroom ${capitalize(
        this.props.pcategorytype
      )} ${capitalize(propertyType)}${actionStr}${searchParams.areas
        }. Check here for the finest collection of ${searchParams.areas
        } property.`
    }
    if (
      (searchParams.price.min || searchParams.price.max) &&
      searchParams.type
    ) {
      description = `Find the latest ${capitalize(propertyType)}${actionStr}${searchParams.areas
        } here. Redbrik offers a wide range of ${capitalize(
          propertyType
        )}${actionStr}${searchParams.areas}.`
    }
    if (
      (searchParams.price.min || searchParams.price.max) &&
      searchParams.bedrooms
    ) {
      description = `Find ${searchParams.bedrooms} bedroom ${capitalize(
        this.props.pcategorytype
      )} ${capitalize(propertyType)}${actionStr}${searchParams.areas
        } with Redbrik,  Contact us for more details about property in ${searchParams.areas
        }.`
    }
    if (searchParams.type && searchParams.bedrooms) {
      description = `Discover the best ${searchParams.bedrooms
        } bedroom ${capitalize(propertyType)}${actionStr}${searchParams.areas
        }, Contact Redbrik leading estate and letting agents in ${searchParams.areas
        }.`
    }
    if (
      (searchParams.price.min || searchParams.price.max) &&
      searchParams.type &&
      searchParams.bedrooms
    ) {
      description = `Check out ${searchParams.bedrooms} bedroom ${capitalize(
        propertyType
      )}${actionStr}${searchParams.areas} ${getPriceStr(
        searchParams.price
      )} with Redbrik, our experts help you find the perfect ${capitalize(
        propertyType
      )} in ${searchParams.areas}.`
    }

    const isPageLoadBg = typeof this.props.fullpathname === "undefined"

    let sortby_placeholder = searchParams.sortVal === "price-asc" ? "Lowest Price First" : searchParams.sortVal === "price-desc" ? "Highest Price First" : "Sort by";

    //console.log("searchParams ==>", searchParams)


    return (
      <section className="menu-list">
        {!isPageLoadBg && (
          <SeoResults
            title={h1}
            searchParams={searchParams}
            description={description}
            location={this.props.fullpathname}
            pcategorytype={this.props.pcategorytype}
            ptypetag={this.props?.ptypetag}
          />
        )}


      {this.state.screenSize < 901 &&
        <MobileFilter
        {...this.props}
        searchParams={searchParams}
        resultSetState={this.setState}
        areaList={this.props.areaList}
      />
       }
       {this.state.screenSize > 901 &&
        <RefineFilter
        {...this.props}
        searchParams={searchParams}
        resultSetState={this.setState}
        areaList={this.props.areaList}
      />
       }

        <div className="menu-wrapper">
        {this.state.areaList.length ? (
            <div className="map-view d-none d-lg-block map-view-wrap">
              {!isMobileOnly && (
                <MapResultsDesktop hits={this.state.algoliadata?.hits ? this.state.algoliadata?.hits : []} />
              )}
              {isMobileOnly && <></>}

            </div>
          ) : (
            <>
              loading...
            </>
          )}
          <div className="container">
          <div className="form-wrapper">


            <div className="menu-details">
              <Row className="inside-details">
                <Col lg={12}>
                  <h1 className="sm-text">
                    {h1.replace(/-/g, " ")}{" "}
                    <i className="icon-identifier" onClick={() => { this.setState({ showDesc: !this.state.showDesc }) }}></i>
                  </h1></Col>
                <Col lg={7}>
                  <div className="sm-details-wrapper d-flex align-items-center">
                    {/* <a
                        className="grid-trigger search-result-view"
                        onClick={e => { e.preventDefault(); this.props?.toggleLayout() }}
                      // title={`Grid view`}
                      >
                        <i className="icon-grid"></i>
                        {!this.props.gridView && (
                          <span className="list-view-search active">Grid <span className="d-lg-inline-block d-none">View</span></span>
                        )}
                        {this.props.gridView && (
                          <span className="grid-view-search active">List <span className="d-lg-inline-block d-none">View</span></span>
                        )}
                      </a> */}

                    <div className="prices search-select-box">
                      <Select
                        options={sort_by_options}
                        placeholder={sortby_placeholder}
                        classNamePrefix={"select-opt"}
                        isSearchable={false}
                        onChange={this.sortByChange}
                        styles={customStyles}

                      // /components={{ DropdownIndicator: () => <DownIndicator /> }}
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={5}>
                  <p>{this.state.algoliadata?.total > 0 ? this.state.algoliadata?.total : 0} results</p>
                </Col>
              </Row>
              {this.state.showDesc &&
                <p className="desc search_text">
                  Discover a wide range of {h1.replace(/-/g, " ").replace('New', 'new').replace('Bedroom', 'bedroom')} with Redbrik
                  Estate Agents. To filter these results, use our filter above,
                  or for more information about {pType} {this.props.fullpathname == '/new-homes/current-developments/in-stonegravels/type-terraced-house/' ? <a href="/property-for-sale/in-stonegravels/">property in Stonegravels</a> : ` property in${" "}

                      ${toTitleCase(areaName.replace("%20", " "))}`}

                  , please <Link to="/contact/">contact us</Link>.
                </p>
              }

            </div>

            <div className={`property-card-list property_list_results mb-5 pb-3 ${this.props.gridView ? 'grid-results' : ''}`} id="property_list_results">



              {!this.isUserInteraction &&
                (this.state.algoliadata?.total > 0 ?
                  <SearchListItems modalSharefrndformOpen={this.state.modalSharefrndformOpen} closeSharefrndformModal = {this.closeSharefrndformModal} openSharefrndformModal= {this.openSharefrndformModal } hits={this.state.algoliadata?.hits} tag={this.props.ptypetag} noResult={false} department='newhomes' />
                  :
                  <>
                     <div className="no-properties-text mb-5">
                      <h4>
                        Unfortunately, we do not currently have any properties that match
                        your search criteria.
                        <p>We have selected some of our showcase properties for you to browse below. Alternatively, you can search again in the bar above.</p>
                      </h4>
                      </div>
                      <NoResultList department={'newhomes'} status={(this.props.ptype === "lettings") ? "To Let" : "For Sale"} /> 
                  </>
                )
              }
                {this.state.algoliadata?.total > 20 &&
                  <div className="algolia-pagination">
                    <LoadpreviousPagination total={this.state.algoliadata.total} hitsPerPage={this.state.algoliadata.hitsPerPage} current_page_number={this.state.algoliadata.current_page_number} number_of_pages={this.state.number_of_pages} location_path={this.props.fullpathname} />
                    <LoadPagination total={this.state.algoliadata.total} hitsPerPage={this.state.algoliadata.hitsPerPage} current_page_number={this.state.algoliadata.current_page_number} number_of_pages={this.state.algoliadata.number_of_pages} location_path={this.props.fullpathname} />
                    <LoadmorePagination total={this.state.algoliadata.total} hitsPerPage={this.state.algoliadata.hitsPerPage} current_page_number={this.state.algoliadata.current_page_number} number_of_pages={this.state.algoliadata.number_of_pages} location_path={this.props.fullpathname} /> 
                  </div>
                }


            </div>
          </div>
          </div>
         
        </div>

        <Footer
          popular_search="results"
          searchtype={this.props.ptype == "lettings" ? "rentals" : "sales"}
          popularSearch="propertyresults"
          searchBedroomfield={searchParams.bedrooms}
          searchPtype={searchParams.type}
          Searcharea={searchParams}
        />
      </section>
    )
  }
}

const PropertySearchSalesWrap = (props) => {
  return (
    <GlobalContext.Consumer>
      {globalProps => {
        //console.log("globalProps", globalProps)
        return (
          <>
            {globalProps?.areaList.length > 0 ? <PropertySearchSales {...props} {...globalProps} /> : ''}
          </>
        )
      }}
    </GlobalContext.Consumer>
  )
}

export default PropertySearchSalesWrap;