import React from "react";
import Header from "../../../components/Header/Header"
import SearchResultPage from "../../../templates/search-results-new-homes-template";
import Footer from "../../../components/Footer/Footer"
import {
    parseSearchUrl,
    savedSearchParams,
    propertyH1
} from "../../../components/common/site/utils";

const NewHomes = (props) => {

    const fullpath = props.location.pathname
    const location = props.location
    const fullpathone = props.location
    const searchParams = parseSearchUrl("sales", fullpath);

    return (
        <div className="search-results">
            <Header />
            <SearchResultPage locationname="/new-homes/current-developments/" pcategorytype="residential" ptype="sales" ptypetag="for-sale" pstatustype={["For Sale", "available"]} fullpathone={fullpathone} fullpathname={fullpath} location={location}/>
            {/* <Footer popular_search="results" searchtype="sales"/> */}
            
        </div>
    );
};

export default NewHomes;
